/* stylelint-disable indentation */
/* stylelint-disable value-list-comma-newline-after */

/* latin */
@font-face {
  font-display: swap;
  font-family: Antonio;
  font-weight: 700;
  src: local('Antonio'),
    url('/assets/fonts/antonio/Antonio-VariableFont_wght.woff2') format('woff2'),
    url('/assets/fonts/antonio/Antonio-VariableFont_wght.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-display: swap;
  font-family: Lora;
  font-style: italic;
  font-weight: 400 600;
  src: local('Lora'),
    url('/assets/fonts/lora/Lora-Italic-VariableFont_wght.woff2')
      format('woff2'),
    url('/assets/fonts/lora/Lora-Italic-VariableFont_wght.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-stretch: 100%;
  font-weight: 400 700;
  src: local('Open Sans'),
    url('/assets/fonts/open-sans/OpenSans-VariableFont_wdth,wght.woff2')
      format('woff2'),
    url('/assets/fonts/open-sans/OpenSans-VariableFont_wdth,wght.woff')
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
