@use 'sass:map';
@use '../../styles/utils' as *;

$grid-cols-md: map.get(map.get($map-grid-props, md), cols);
$grid-cols-xl: map.get(map.get($map-grid-props, xl), cols);
$gutter-md: $grid-gutter-horizontal-desktop * 1px;
$triangle-md: calc($gutter-md + (100vw - $gutter-md * 2) / $grid-cols-md * 3);
$triangle-xl: calc((50vw - ($breakpoint-xl * 1px)) / 2 + ($breakpoint-xl * 1px) / $grid-cols-xl * 4);

.impactPanelContainer {
  align-items: center;
  display: flex;
  fill: $color-orange;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.contentContainer {
  color: $color-slate;
  padding: rem(80) rem(0);
  text-align: center;

  @include breakpoint-md {
    padding: rem(85) 0;
  }

  @include breakpoint-xl {
    padding: rem(80) 0;
  }

  h2 {
    margin-bottom: rem(16);
  }

  span {
    margin-top: rem(32);
  }
}

.triangleBackground {
  background-color: $color-light-orange;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

// Desktop Triangle Background
.triangleContainerDesktop {
  height: 100%;
  margin: 0 auto;
  max-width: rem(1200);
  position: relative;
  width: 100%;
}

.backgroundTriangleTop {
  fill: $color-orange;
  height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotateX(180deg) rotateY(180deg);
  width: 100%;
}

.backgroundTriangleBottom {
  bottom: 0;
  top: auto;
  transform: rotateY(180deg);
}

.halfWidthOrangeLightBackground {
  background-color: $color-light-orange;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 1px;
  top: 0;
  z-index: -2;
}

.halfWidthOrangeBackground {
  background-color: $color-orange;
  bottom: 0;
  left: 30vw;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -3;
}

// Mobile Triangle Background
.backgroundTriangleLeft {
  bottom: 0;
  fill: $color-orange;
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.backgroundTriangleRight {
  left: auto;
  right: 0;
  transform: rotateY(180deg);
}
