@use '../../styles/utils' as *;

.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: rem(588);
  padding: rem(128) 0 rem(120) 0;
  text-align: center;
}

.notFoundHero {
  margin: 0 auto;
  padding: 0 0 rem(16);
  width: rem(80);
}

.notFoundCopy {
  padding: rem(16) 0 0;
}
