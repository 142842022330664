@use 'font' as *;

// Typography
$html-font-size: 16;
$body-font-family: 'Open Sans', sans-serif;
$heading-font-family: 'Antonio', sans-serif;
$heading-secondary-font-family: 'Lora', sans-serif;

// Levande brand standard colors
$color-black: #000;
$color-white: #fff;
$color-slate: #323737;
$color-light-slate: #d9dbde;
$color-lime: #bee13e;
$color-light-lime: #edf7c7;
$color-yellow: #ffcb48;
$color-light-yellow: #fce8c0;
$color-orange: #fd754c;
$color-light-orange: #ffdfd4;
$color-lilac: #e894ed;
$color-light-lilac: #fde1ff;
$color-blue: #739bff;
$color-light-blue: #d6e3ff;
$color-error: #db3939;
$color-light-error: #fdd0d0;
$color-osmium: #6c818d;

// Media query breakpoints
$breakpoint-sm: 576; // ≥ 576px
$breakpoint-md: 768; // ≥ 768px
$breakpoint-lg: 992; // ≥ 992px
$breakpoint-xl: 1200; // ≥ 1200px
$breakpoint-xxl: 1400; // ≥ 1400px
$breakpoint-xxxl: 2048; // ≥ 2048

// Grid
$map-grid-props: (
  'xs': (
    breakpoint: 0,
    cols: 4
  ),
  'sm': (
    breakpoint: $breakpoint-sm,
    cols: 4
  ),
  'md': (
    breakpoint: $breakpoint-md,
    cols: 8
  ),
  'lg': (
    breakpoint: $breakpoint-lg,
    cols: 8
  ),
  'xl': (
    breakpoint: $breakpoint-xl,
    cols: 12
  ),
  'xxl': (
    breakpoint: $breakpoint-xxl,
    cols: 12
  ),
  'xxxl': (
    breakpoint: $breakpoint-xxxl,
    cols: 12
  )
);

// Grid
$grid-container-xl: $breakpoint-xl; // ≥ 1200px
$grid-container-xxl: $breakpoint-xxl; // ≥ 1400px
$grid-container-xxxl: $breakpoint-xxxl; // ≥ 2048px

$grid-gutter-horizontal-mobile: 24;
$grid-gutter-horizontal-tablet: 48; // 64;
$grid-gutter-horizontal-desktop: 48; // 100;

$preview-banner-height: 2.5rem;

// Header
$header-height-mobile: 72;
$header-height-tablet: 80;
$header-height-desktop: 112;

// Section
$section-gutter-vertical-mobile: 40;
$section-gutter-vertical-tablet: 64;
$section-gutter-vertical-desktop: 80;

// Animations
$transition-timing-ease-out: cubic-bezier(0.25, 1, 0.5, 1);
$transition-timing-ease-out-expand: cubic-bezier(1, 0, 1, 0);
$transition-timing-ease-out-collapse: cubic-bezier(0, 1, 0, 1);
