/* stylelint-disable scss/operator-no-newline-after */
@use '../../styles/utils' as *;
@use 'sass:map';

$grid-cols-md: map.get(map.get($map-grid-props, md), cols);
$grid-cols-xl: map.get(map.get($map-grid-props, xl), cols);
$gutter-md: $grid-gutter-horizontal-desktop * 1px;
$triangle-md: calc($gutter-md + (100vw - $gutter-md * 2) / $grid-cols-md * 3);
$triangle-xl: calc(
  (100vw - (($breakpoint-xl * 1px) + 8px)) / 2 + ($breakpoint-xl * 1px) /
    $grid-cols-xl * 4
);
$height-sm: rem(320);
$height-md: rem(400);
$height-xl: rem(480);

.hero {
  height: $height-sm;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include breakpoint-md {
    height: $height-md;
    margin-bottom: rem(64);
  }

  @include breakpoint-xl {
    height: $height-xl;
    margin-bottom: rem(80);
  }

  @include breakpoint-xxl {
    height: $height-xl;
  }
}

.heroPlain {
  display: flex;
  justify-content: center;
  padding: rem(40) rem(24);
  text-align: center;

  @include themify($themes) {
    color: themed('textColorSecondary');
  }

  @include breakpoint-md {
    padding: rem(64);
  }

  @include breakpoint-xl {
    padding: rem(80) 0;
  }
}

.heroPlainCentered {
  display: flex;
  justify-content: center;
}

.plainWrapper {
  @include breakpoint-xl {
    max-width: rem(588);
  }
}

.plainDescription {
  @include base-paragraph-regular;
  @include font-size(18 26);

  margin-top: rem(16);
}

.mobileHeroContent {
  background-color: $color-lime;
  height: auto;
  margin-bottom: rem(40);
  margin-top: rem(-1);
  padding-bottom: rem(24);
  padding-left: 32px;

  @include themify($themes) {
    // Mobile Hero Small Header Text Color
    color: themed('textColor');
    background-color: themed('backgroundColor');
  }
}

.mobileTabContainer {
  padding-bottom: 0;
}

.heroContent {
  align-items: center;
  background-color: transparent;
  display: flex;

  // Hero Small Header Text Color
  @include themify($themes) {
    color: themed('textColor');
  }

  @include breakpoint-md {
    height: $height-md;
  }

  @include breakpoint-xl {
    height: $height-xl;
  }

  @include breakpoint-xl {
    height: $height-xl;
  }
}

.heroBackground {
  height: 100%;
  position: absolute;
  width: 100%;

  @include breakpoint-md {
    left: 0;
  }
}

.heroBackgroundBlock {
  background-color: $color-lime;
  height: 100%;
  position: absolute;
  width: 0;

  @include themify($themes) {
    background-color: themed('backgroundColor');
  }

  @include breakpoint-md {
    width: calc($triangle-md);
  }

  @include breakpoint-xl {
    width: calc($triangle-xl);
  }
}

.heroBackgroundTriangle {
  bottom: 0;
  fill: $color-lime;
  height: auto;
  position: absolute;
  width: 100%;

  @include themify($themes) {
    fill: themed('backgroundColor');
  }

  @include breakpoint-md {
    bottom: 0;
    height: 100%;
    left: calc($triangle-md - 1px);
    position: absolute;
    transform: none;
    width: initial;
  }

  @include breakpoint-xl {
    left: calc($triangle-xl - 1px);
  }
}

.backgroundMediaWrapper {
  height: 100%;
  overflow-x: hidden;
  position: absolute;
  width: 100%;

  @include breakpoint-md {
    img {
      height: 100%;
      left: 0;
      width: 100%;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    object-position: 0% 50%;
    position: absolute;
    width: 100%;
  }
}

.backgroundMedia {
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;

  @include breakpoint-md {
    width: calc(100vw - $triangle-md);
  }

  @include breakpoint-lg {
    width: calc(100vw - $triangle-xl);
  }
}

.colorBar {
  bottom: 0;
  display: inline-flex;
  height: 80px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1;

  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
}
