@use '../../styles/utils' as *;

.heroLinkWrapper {
  color: $color-blue;
  text-decoration: underline;
}

.resultItem {
  border-bottom: rem(1) solid rgba(0, 0, 0, 10%);
  margin-bottom: rem(36);
  padding: 0 rem(24) rem(12) rem(24);

  p {
    color: $color-slate;
    font-size: rem(16);
    font-weight: 700;
    margin-bottom: rem(6);

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .excerpt {
    font-weight: normal;
    margin-bottom: 0;
    margin-top: rem(12);
    opacity: 0.5;
  }

  .resultItemLinkWrapper {
    color: $color-blue;
    font-size: rem(14);
    overflow-wrap: anywhere;
    text-decoration: underline;
  }
}

.paginationWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: rem(24);

  @include breakpoint-md {
    margin-bottom: rem(88);
  }

  @include breakpoint-xl {
    margin-bottom: rem(96);
  }
}

.pdfSvgWrapper {
  margin-left: auto;

  svg {
    height: rem(24);
    width: rem(24);
  }
}

.narrowContent {
  @include breakpoint-xl {
    max-width: rem(1100);
  }
}
