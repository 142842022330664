/* stylelint-disable scss/operator-no-newline-after */
@use '../../styles/utils' as *;
@use 'sass:map';

$grid-cols-md: map.get(map.get($map-grid-props, md), cols);
$grid-cols-xl: map.get(map.get($map-grid-props, xl), cols);
$gutter-md: $grid-gutter-horizontal-desktop * 1px;
$triangle-md: calc($gutter-md + (100vw - $gutter-md * 2) / $grid-cols-md * 3);
$triangle-xl: calc(
  (100vw - ($breakpoint-xl * 1px)) / 2 + ($breakpoint-xl * 1px) / $grid-cols-xl *
    4
);
$height-sm: rem(320);
$height-md: rem(464);
$height-xl: rem(640);

.hero {
  height: $height-sm;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include breakpoint-md {
    height: $height-md;
    margin-bottom: rem(64);
  }

  @include breakpoint-xl {
    height: $height-xl;
    margin-bottom: rem(80);
  }
}

.mobileHeroWrapper {
  background-color: $color-lime;
  height: auto;
  margin-bottom: rem(40);

  &Themed {
    @extend .mobileHeroContent;

    margin-bottom: rem(40);

    @include themify($themes) {
      background-color: themed('backgroundColor');
    }
  }

  &Slate {
    @extend .mobileHeroContent;

    background-color: $color-slate;
    margin-bottom: rem(40);
  }
}

.mobileHeroContent {
  margin-top: rem(-1);
  padding-bottom: rem(24);

  // Hero Large Mobile Header Text Color
  @include themify($themes) {
    color: themed('textColor');
  }
}

.heroContent {
  align-items: center;
  background-color: transparent;
  display: flex;

  // Hero Large Header Text Color
  @include themify($themes) {
    color: themed('textColor');
  }

  @include breakpoint-md {
    height: $height-md;
  }

  @include breakpoint-xl {
    height: $height-xl;
  }

  @include breakpoint-xl {
    height: $height-xl;
  }
}

.heroBackground {
  height: 100%;
  position: absolute;
  width: 100%;

  @include breakpoint-md {
    left: 0;
  }
}

.heroBackgroundBlock {
  background-color: $color-lime;
  height: 100%;
  position: absolute;
  width: 0;

  @include breakpoint-md {
    width: $triangle-md;
  }

  @include breakpoint-xl {
    width: $triangle-xl;
  }

  &Themed {
    @extend .heroBackgroundBlock;

    @include themify($themes) {
      background-color: themed('backgroundColor');
    }
  }

  &Slate {
    @extend .heroBackgroundBlock;

    background-color: $color-slate;
  }
}

.heroBackgroundTriangle {
  bottom: 0;
  fill: $color-lime;
  height: auto;
  position: absolute;
  width: 100%;

  @include breakpoint-md {
    bottom: 0;
    height: 100%;
    left: calc($triangle-md - 1px);
    position: absolute;
    transform: none;
    width: initial;
  }

  @include breakpoint-xl {
    left: calc($triangle-xl - 1px);
  }

  &Themed {
    @extend .heroBackgroundTriangle;

    @include themify($themes) {
      fill: themed('backgroundColor');
    }
  }

  &Slate {
    @extend .heroBackgroundTriangle;

    fill: $color-slate;
  }
}

.backgroundMediaWrapper {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  @include breakpoint-md {
    img {
      height: 100%;
      left: 0;
      width: 100%;
    }
  }
}

.backgroundMedia {
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;

  @include breakpoint-md {
    width: calc(100vw - $triangle-md);
  }

  @include breakpoint-lg {
    width: calc(100vw - $triangle-xl);
  }
}

.mobileButtonWrapper {
  align-items: center;
  bottom: rem(40);
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: rem(28);
  width: 100%;
  z-index: 0;

  // for extra narrow screens (e.g. Galaxy Fold devices)
  @media screen and (max-width: 300px) {
    justify-content: center;
    right: 0;
  }
}

.buttonWrapper {
  align-items: center;
  display: flex;
  margin-bottom: rem(40);

  @include breakpoint-md {
    margin-bottom: rem(40);
  }

  @include breakpoint-xl {
    margin-bottom: rem(64);
  }
}

.scrollRef {
  @include scrolloffset;
}

.playButton {
  align-items: center;
  background-color: $color-white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-left: rem(16);
  user-select: none;
}

.progressRing {
  border-radius: 50%;
  height: rem(56);
  position: absolute;
  width: rem(56);
  z-index: 0;
}

.playIconButtonWrapper {
  align-items: center;
  background-color: $color-white;
  border-radius: 50%;
  display: flex;
  height: rem(48);
  justify-content: center;
  width: rem(48);
  z-index: 0;
}

.playIconWrapper {
  height: auto;
  width: 16px;
}

.playIconPad {
  margin-left: 2px;
  width: 16px;
}

:export {
  colorblue: $color-blue;
  colorlightblue: $color-light-blue;
  colorlightlilac: $color-light-lilac;
  colorlightlime: $color-light-lime;
  colorlightorange: $color-light-orange;
  colorlightyellow: $color-light-yellow;
  colorlightslate: $color-light-slate;
  colorlilac: $color-lilac;
  colorlime: $color-lime;
  colororange: $color-orange;
  coloryellow: $color-yellow;
  colorslate: $color-slate;
}
