@use '../../styles/utils' as *;
@use 'sass:map';

$grid-cols-md: map.get(map.get($map-grid-props, md), cols);
$grid-cols-xl: map.get(map.get($map-grid-props, xl), cols);

.tabContainer {
  bottom: 0;
  display: inline-flex;
  height: 80px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1;

  @include themify($themes) {
    background-color: themed('backgroundColor');
  }

  @include breakpoint-sm {
    padding: 0 rem($grid-gutter-horizontal-mobile);
  }

  @include breakpoint-md {
    padding: 0 rem($grid-gutter-horizontal-tablet);
  }

  @include breakpoint-lg {
    padding: 0 rem($grid-gutter-horizontal-desktop);
  }

  @include breakpoint-xl {
    max-width: rem($breakpoint-xl);
    padding: 0 rem($grid-gutter-horizontal-desktop);
  }

  @include breakpoint-xxl {
    max-width: rem($breakpoint-xxl);
    padding: 0 rem($grid-gutter-horizontal-desktop);
  }
}

.newsEventsTab {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 64px;
  justify-content: center;
  position: relative;
  right: 0;
  top: 16px;
  width: 50%;

  &:hover {
    cursor: pointer;
  }
}

.selectedTab {
  background-color: #ffff;
  
  // Selected Tab Text Color
  @include themify($themes) {
    color: themed('textColorSecondary');
  }
}

.spacer {
  width: 32px;
}

.unselectedTab {
  background-color: rgba($color: #000, $alpha: 5%); // Opacity % for Unselected Tab
  z-index: 2;
  
  // Unselected Tab Text Color
  @include themify($themes) {
    color: themed('textColor');
  }
}

.mobileTabContainer {
  bottom: 0;
  display: inline-flex;
  height: 80px;
  justify-content: space-between;
  margin-right: 32px;
  position: relative;
  text-align: center;
  width: 90%;
  z-index: 1;

  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
}
