@use '../core' as *;

$body-font-size: rem(16);

/* H1 */
@mixin text-h1 {
  font-family: $heading-font-family;
  font-size: rem(48);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: rem(56);
  text-transform: uppercase;

  @include breakpoint-lg {
    font-size: rem(56);
    line-height: rem(64);
  }
}

@mixin text-h1-hero {
  font-family: $heading-font-family;
  font-size: rem(56);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: rem(64);
  text-transform: uppercase;

  @include breakpoint-lg {
    font-size: rem(86);
    line-height: rem(94);
  }
}

/* H2 */
@mixin text-h2 {
  font-family: $heading-secondary-font-family;
  font-size: rem(32);
  font-style: italic;
  font-weight: 600;
  line-height: rem(40);

  @include breakpoint-lg {
    font-size: rem(44);
    line-height: rem(52);
  }
}

/* H3 */
@mixin text-h3 {
  font-family: $heading-secondary-font-family;
  font-size: rem(24);
  font-style: italic;
  font-weight: 600;
  line-height: rem(32);

  @include breakpoint-lg {
    font-size: rem(34);
    line-height: rem(40);
  }
}

/* H4 */
@mixin text-h4 {
  font-family: $heading-secondary-font-family;
  font-size: rem(20);
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: rem(24);

  @include breakpoint-lg {
    font-size: rem(24);
    line-height: rem(32);
  }
}

/* Base Large */
@mixin base-large-regular {
  font-size: rem(18);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: rem(26);
}

@mixin base-large-semibold {
  font-size: rem(18);
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: rem(26);
}

@mixin base-large-bold {
  font-size: rem(18);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: rem(26);
}

/* Base Paragraph */
@mixin base-paragraph-regular {
  font-size: rem(16);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: rem(24);
}

@mixin base-paragraph-semibold {
  font-size: rem(16);
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: rem(24);
}

@mixin base-paragraph-bold {
  font-size: rem(16);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: rem(24);
}

/* Base Small */
@mixin base-small-regular {
  font-size: rem(14);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: rem(20);
}

@mixin base-small-semibold {
  font-size: rem(14);
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: rem(20);
}

@mixin base-small-bold {
  font-size: rem(14);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: rem(20);
}

/* Base Smallest */
@mixin base-smallest-regular {
  font-size: rem(12);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: rem(16);
}

@mixin base-smallest-semibold {
  font-size: rem(12);
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: rem(16);
}

@mixin base-smallest-bold {
  font-size: rem(12);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: rem(16);
}
