/* stylelint-disable function-parentheses-space-inside */
@use 'sass:list';
@use 'sass:map';
@use 'variables' as *;
@use 'functions' as *;
@use '../../../../node_modules/breakpoint-sass/stylesheets/_breakpoint' as *;

/*
 * Mixin for scroll offset
 */
@mixin scrolloffset {
  scroll-margin-top: rem($header-height-mobile + 40);

  @include breakpoint-md {
    scroll-margin-top: rem($header-height-tablet + 40);
  }

  @include breakpoint-lg {
    scroll-margin-top: rem($header-height-desktop + 40);
  }
}

/*
 * Mixin for theming
 */
@mixin themify($themes) {
  @each $theme, $map in $themes {
    :global(.theme-#{$theme}) & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map.get(map.get($themes, $theme), '#{$key}');
        $theme-map: map.merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }
      @content;

      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map.get($theme-map, $key);
}

// Font size mixin. Expects an array of 2 values: font-size line-height;
@mixin font-size($font-size) {
  font-size: rem(list.nth($font-size, 1));
  line-height: rem(list.nth($font-size, 2));
}

// Mixin for adding transition properties
// E.g. @include transition(background-color border-color, 0.3s, linear);
@mixin transition($props, $duration: 0.3s, $timing: linear) {
  $transition: ();
  $willchange: ();

  @each $prop in $props {
    $transition: list.append(
      $transition,
      ($prop $duration $timing),
      $separator: comma
    );
    $willchange: list.append($willchange, ($prop), $separator: comma);
  }

  transition: $transition;
  will-change: $willchange;
}

// Preset mixins for animation duration and timing-function
// E.g. @include transition-linear(background-color border-color);
@mixin transition-linear($props) {
  @include transition($props, 0.3s, linear);
}

@mixin transition-linear-fast($props) {
  @include transition($props, 0.15s, linear);
}

@mixin transition-linear-slow($props) {
  @include transition($props, 0.6s, linear);
}

@mixin transition-ease-out($props) {
  @include transition($props, 0.4s, $transition-timing-ease-out);
}

@mixin transition-ease-out-fast($props) {
  @include transition($props, 0.2s, $transition-timing-ease-out);
}

@mixin transition-ease-out-slow($props) {
  @include transition($props, 0.6s, $transition-timing-ease-out);
}

@mixin transition-expand($props) {
  @include transition($props, 0.15s, $transition-timing-ease-out-expand);
}

@mixin transition-collapse($props) {
  @include transition($props, 0.15s, $transition-timing-ease-out-collapse);
}

// Transition to use on all link hover states
@mixin transition-link($props) {
  @include transition-ease-out-fast($props);
}

// Media Queries
@mixin create-mq($bp) {
  @include breakpoint(rem($bp)) {
    @content;
  }
}

@mixin breakpoint-sm {
  @include create-mq($breakpoint-sm) {
    @content;
  }
}

@mixin breakpoint-md {
  @include create-mq($breakpoint-md) {
    @content;
  }
}

@mixin breakpoint-lg {
  @include create-mq($breakpoint-lg) {
    @content;
  }
}

@mixin breakpoint-xl {
  @include create-mq($breakpoint-xl) {
    @content;
  }
}

@mixin breakpoint-xxl {
  @include create-mq($breakpoint-xxl) {
    @content;
  }
}

@mixin breakpoint-xxxl {
  @include create-mq($breakpoint-xxxl) {
    @content;
  }
}
